import React, {useEffect, useState} from 'react';
import './App.scss';
import build_information from './build_information.json'

function App() {

  const [deploymentInfo, setDeploymentInfo] = useState<{
    [key: string]: string
  }>({});

  const [urlQuery, setUrlQuery] = useState<{
    [key: string]: string
  }>({});

  useEffect(() => {

    fetch("/deployment_info.json?_cache_buster=" + Date.now())
      .then(res => res.json())
      .then(data => {
        window.deployment_info = data
        return setDeploymentInfo(data);
      })
      .catch((e) => {
        console.error("Unable to get deployment info!");
        console.error(e);
      })


      /* eslint-disable-next-line no-restricted-globals */
      let queryStr = location.search.substring(1);
      let query = queryStr.split("&").reduce((prev: any, current) => {
        let [key, value] = current.split("=");
        prev[key] = value;
        return prev;
      }, {})

      setUrlQuery(query);
  }, []);

  return (
    <>
      <h1>Hello world!</h1>
      <div style={{display: urlQuery.showEnv === "true" ? "block" : "none"}}>
        <h1>Deployment information:</h1>
        {Object.entries(deploymentInfo).map(key => (
          <p>
            <span>{key[0]}</span> <span>{key[1]}</span>
          </p>
        ))}
        <h1>Build information:</h1>
        {Object.entries(build_information).map(key => (
          <p>
            <span>{key[0]}</span> <span>{key[1]}</span>
          </p>
        ))}
      </div>
    </>
  );
}

export default App;
